import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {removeSnackbar} from "../reduxUtils/reduxSetup";

let displayed = [];

const Notifier = () => {
    const dispatch = useDispatch();
    const snackbars = useSelector(store => store.snackbars);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        snackbars.forEach(({key, message, options}) => {

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) {
                return null;
            }

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar({key: myKey}));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);

        })
    }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
