import React from 'react';
import Logo from "../images/Logo.png";
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar} from "@material-ui/core";
import SearchBar from "./SearchBar";
import {Home} from "react-feather";
import {Settings} from "react-feather";
import {LogOut} from "react-feather";
import { Auth } from '@aws-amplify/auth';
import {useDispatch} from "react-redux";
import {toggleLoading, toggleShouldLoadDefault} from "../reduxUtils/reduxSetup";

const useStyles = makeStyles({
    root: {
        background: 'transparent',
        boxShadow: "none",
        position: "static",
        minHeight: "0",
        // height: "8vh",
    },
    tracText: {
        color: "#55585e",
        fontFamily: "Poppins, serif",
        /*order: 1,*/
        height: "auto",
        width: "auto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "0.8em",
        // lineHeight: "1.5em",
        marginRight: "0.25em",
    },
    logoImg: {
        height: "1.2em",
        width: "1.0em",
    },
    searchBarContainer: {
        marginLeft: "6%",
    },
    iconContainer: {
        position: "absolute",
        display: "flex",
        flexDirection: "row",
        right: "1%",
    },
    icon: {
        color: "#55585e",
        paddingLeft: "0.6em",
        paddingRight: "0.6em",
        strokeWidth: 1,
        '&:hover': {
            filter: "opacity(0.5)",
        }
    }
});

const onHomeClick = () => {
};

const onSettingsClick = () => {
};


const TopNavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onLogoutClick = async() => {
        try {
            dispatch(toggleLoading({loading: true}));
            await Auth.signOut({ global: true });
        } catch (error) {
            console.log('error signing out: ', error);
        } finally {
            dispatch(toggleLoading({loading: false}));
            dispatch(toggleShouldLoadDefault({shouldLoadDefault: true}));
            setTimeout(() => document.location.reload(), 2000);
        }
    };

    return (
        // <div className="navContainer">
        //     <p className="tracText">TRAC</p>
        //     <img className="landingPageImg" src={Logo} alt="Analytics Image"/>
        // </div>
        <AppBar className={classes.root}>
            <Toolbar variant={"dense"}>
                <p className={classes.tracText}>TRAC</p>
                <img className={classes.logoImg} src={Logo} alt="Logo"/>
                <div className={classes.searchBarContainer}>
                    <SearchBar/>
                </div>
                <div className={classes.iconContainer}>
                    {/*<Home className={classes.icon} size={"1.4em"} onClick={onHomeClick}/>*/}
                    {/*<Settings className={classes.icon} size={"1.4em"} onClick={onSettingsClick}/>*/}
                    <LogOut className={classes.icon} size={"1.4em"} onClick={onLogoutClick}/>
                </div>
            </Toolbar>
        </AppBar>
    );
};
export default TopNavBar;
