import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    tab: {
        width: "1em"
    },
    tabLabel: {
        fontSize: "1em",
        textTransform: "capitalize",
    },
});

const CompanyTabs = (props) => {
    const classes = useStyles();
    let history = useHistory();

    // const [value, setValue] = useState(1);
    //
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    function onClick(page) {
        history.push(page);
    }

    return (
        <Paper square>
            <Tabs
                value={props.tabValue}
                indicatorColor="primary"
                textColor="primary"
                // onChange={handleChange}
            >
                <Tab className={classes.tab} onClick={() => onClick("/competitors")} label={<span className={classes.tabLabel}>Add/Delete</span>} />
                <Tab className={classes.tab} onClick={() => onClick("/traffic")} label={<span className={classes.tabLabel}>Web Traffic</span>} />
                <Tab className={classes.tab} onClick={() => onClick("/compositeMomentum")} label={<span className={classes.tabLabel}>Projected Momentum</span>} />
            </Tabs>
        </Paper>
    );
};
export default CompanyTabs;
