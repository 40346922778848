import React from 'react';

import { ResponsiveLine } from '@nivo/line';
import { categoricalColorSchemes } from '@nivo/colors';
import chartTheme from "../../themes/nivoChartsTheme";
import LineChartGenerator from "../LineChartGenerator";
import {useDispatch, useSelector} from "react-redux";
import {updateCompositeMomentumTimeFrame} from "../../../reduxUtils/reduxSetup";
import {timeFrameSelect} from "../chartUtils";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
const { dark2 } = categoricalColorSchemes;
// const styleById = {
//     // "Human Interest Actual": {
//     //     strokeDasharray: '12, 6',
//     //     strokeWidth: 2,
//     // strokeDasharray: '1, 16',
//     // strokeWidth: 8,
//     // strokeLinejoin: 'round',
//     // strokeLinecap: 'round',
//     // },
//     "Human Interest Predicted": {
//         strokeDasharray: '12, 6',
//         strokeWidth: 1,
//     },
//     "Guideline Predicted": {
//         strokeDasharray: '12, 6',
//         strokeWidth: 1,
//     },
//     // rhum: {
//     //     strokeDasharray: '6, 6',
//     //     strokeWidth: 4,
//     // },
//     default: {
//         strokeWidth: 1,
//     },
// };

const styleById = (id) => {
    if (id.includes("Projected")) {
        return ({
                strokeDasharray: '12, 6',
                strokeWidth: 1,
        });
    } else {
        return ({
                strokeWidth: 1,
        });
    }
};

const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
        <path
            key={id}
            d={lineGenerator(
                data.map(d => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                }))
            )}
            fill="none"
            stroke={color}
            style={styleById(id)}
        />
    ))
};

let sampleData = [
    {
        id: 'Sample Actual',
        data: [
            // { x: '2019-10-01 00:00:00', y: 40 },
            // { x: '2019-11-01 00:00:00', y: 50},
            // { x: '2019-12-01', y: 65 },
            // { x: '2020-01-01', y: 90 },
            // { x: '2020-02-01', y: 120 },
            { x: '2020-03-01 00:00:00', y: null },
            { x: '2020-04-01 00:00:00', y: null },
            { x: '2020-05-01 00:00:00', y: null },
        ],
        label: "sample"
    },
    {
        id: 'Sample Projected',
        data: [
            { x: '2020-05-01 00:00:00', y: null },
            { x: '2020-06-01 00:00:00', y: null },
            { x: '2020-07-01 00:00:00', y: null },
            // { x: '2020-08-01', y: 110 },
            // { x: '2020-09-01', y: 90 },
            // { x: '2020-10-01', y: 130 },
            // { x: '2020-11-01', y: 180},
            // { x: '2020-12-01', y: 140 },
            // { x: '2021-01-01', y: 180 },
        ],
        label: "sample"
    },
];

const MomentumDashedExperimentChart = (props) => {
    // let data=[
    //     {
    //         id: 'Human Interest Actual',
    //         data: [
    //             { x: '2019-10-01', y: 40 },
    //             { x: '2019-11-01', y: 50},
    //             { x: '2019-12-01', y: 65 },
    //             { x: '2020-01-01', y: 90 },
    //             { x: '2020-02-01', y: 120 },
    //             { x: '2020-03-01', y: 160 },
    //             { x: '2020-04-01', y: 130 },
    //             { x: '2020-05-01', y: 170 },
    //         ],
    //         label: "Human Interest"
    //     },
    //     {
    //         id: 'Human Interest Predicted',
    //         data: [
    //             { x: '2020-05-01', y: 170 },
    //             { x: '2020-06-01', y: 110 },
    //             { x: '2020-07-01', y: 100 },
    //             { x: '2020-08-01', y: 110 },
    //             { x: '2020-09-01', y: 90 },
    //             { x: '2020-10-01', y: 130 },
    //             { x: '2020-11-01', y: 180},
    //             { x: '2020-12-01', y: 140 },
    //             { x: '2021-01-01', y: 180 },
    //         ],
    //         label: "Human Interest"
    //     },
    //     {
    //         id: 'Guideline Actual',
    //         data: [
    //             { x: '2019-10-01', y: 170 },
    //             { x: '2019-11-01', y: 150},
    //             { x: '2019-12-01', y: 165 },
    //             { x: '2020-01-01', y: 190 },
    //             { x: '2020-02-01', y: 150 },
    //             { x: '2020-03-01', y: 160 },
    //             { x: '2020-04-01', y: 130 },
    //             { x: '2020-05-01', y: 120 },
    //         ],
    //         label: "Guideline"
    //     },
    //     {
    //         id: 'Guideline Predicted',
    //         data: [
    //             { x: '2020-05-01', y: 120 },
    //             { x: '2020-06-01', y: 110 },
    //             { x: '2020-07-01', y: 110 },
    //             { x: '2020-08-01', y: 130 },
    //             { x: '2020-09-01', y: 140 },
    //             { x: '2020-10-01', y: 120 },
    //             { x: '2020-11-01', y: 110 },
    //             { x: '2020-12-01', y: 130 },
    //             { x: '2021-01-01', y: 140 },
    //         ],
    //         label: "Guideline"
    //     },
    // ];
    let data = useSelector(state => state.companiesData.momentumComposite);
    const timeframe = useSelector(state => state.timeFrames.compositeMomentum);
    const dataIsEmpty = data.length === 0;
    data = dataIsEmpty ? sampleData : data;
    data = data.map((item, index) => ({...item, color: dark2[(Math.floor(index/2)) % dark2.length]}));
    let dataForLegends = dataIsEmpty ? [] : data.filter((item, index) => !(index%2));
    let customEmptyYScale = {
        type: 'linear',
        min: 10,
        max: 100,
        stacked: false,
        reverse: true,
    };

    return (
        <ResponsiveLine
            data={timeFrameSelect(data, timeframe, "M", true)}
            margin={{top: 20,
                right: props.rightMultiplier * Math.max.apply(Math, data.map(function (o) {
                    return o.label.length;
                })),
                bottom: props.bottomMargin,
                left: props.customLeftMargin || props.leftMargin
            }}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d %H:%M:%S',
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={dataIsEmpty ? customEmptyYScale : {
                type: 'linear',
                stacked: false,
                reverse: true,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                // format: '%b %d %y',
                format: '%b %y',
                // tickValues: 'every 4 months',
                tickValues: 4,
                legend: 'Time',
                legendOffset: props.bottomLegendOffset,
                legendPosition: 'middle'
            }}
            axisLeft={{
                format: ',.2r',
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Composite Momentum',
                legendOffset: props.leftLegendOffset,
                legendPosition: 'middle'
            }}
            theme={chartTheme}
            layers={['grid', 'markers', 'areas', DashedLine, 'slices', 'points', 'axes', 'legends', 'crosshair', 'mesh']}
            // colors={{scheme: 'nivo'}}
            colors={d => d.color}
            enablePoints={false}
            pointSize={props.pointSize}
            pointColor={{theme: 'background'}}
            pointBorderWidth={2}
            pointBorderColor={{from: 'serieColor'}}
            pointLabel="y"
            tooltip={props.linePointTooltip}
            pointLabelYOffset={-12}
            useMesh={true}
            animate={props.animate}
            // enableSlices="x"
            // sliceTooltip={({ slice }) => {
            //     console.log(slice);
            //     return (
            //         <div
            //             style={{
            //                 background: 'white',
            //                 padding: '9px 12px',
            //                 border: '1px solid #ccc',
            //             }}
            //         >
            //             {slice.points.map(point => (
            //                 <div
            //                     key={point.id}
            //                     style={{
            //                         color: point.serieColor,
            //                         padding: '3px 0',
            //                     }}
            //                 >
            //                     <strong>{point.serieId}</strong> [{point.data.yFormatted}]
            //                 </div>
            //             ))}
            //         </div>
            //     )
            // }}
            // tooltip={({ id, value, color }) => (
            //     <strong style={{ color }}>
            //         {id}: {value}
            //     </strong>
            // )}
            legends={[
                {
                    // dataFrom: "",
                    // data: [{id: "test", label: "labelTest", color: "#fff"}, {id: "test2", label: "labelTest2", color: "#6da832"}],
                    data: dataForLegends,
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: props.legendTranslateX,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: props.legendItemWidth,
                    itemHeight: props.legendItemHeight,
                    itemOpacity: props.legendItemOpacity,
                    symbolSize: props.legendSymbolSize,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    itemTextColor: props.legendTextColor,
                    // legendFormat: d => `Hello${d.id}`,
                    onClick: props.onLegendClick,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />);
};

const MomentumComposite = () => {
    const dispatch = useDispatch();
    const compositeMomentumTimeFrame = useSelector((state) => state.timeFrames.compositeMomentum);

    const onChange = (e) => {
        dispatch(updateCompositeMomentumTimeFrame({timeFrame: e.target.value}));
    };

    return (
        <LineChartGenerator
            id={"momentumCompositeChart"}
            title={"Projected Momentum"}
            value={compositeMomentumTimeFrame}
            onChange={onChange}
            Chart={MomentumDashedExperimentChart}
            customLeftMargin={90}
        />
    );
};

export default MomentumComposite;
