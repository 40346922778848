import React from 'react';
import Logo from "../images/Logo.png";
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar} from "@material-ui/core";


const useStyles = makeStyles({
    root: {
        background: 'transparent',
        boxShadow: "none",
        zIndex: 0,
        // position: "static",
        // height: "8vh",
    },
    tracText: {
        color: "#55585e",
        fontFamily: "Poppins, serif",
        /*order: 1,*/
        height: "auto",
        width: "auto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "0.8em",
        // lineHeight: "1.5em",
        left: "5%",
    },
    logoImg: {
        height: "1.2em",
        width: "1.0em",
    },
});

const SignInPageNavBar = () => {
    const classes = useStyles();
    return (
        // <div className="navContainer">
        //     <p className="tracText">TRAC</p>
        //     <img className="landingPageImg" src={Logo} alt="Analytics Image"/>
        // </div>
        <AppBar className={classes.root}>
            <Toolbar>
                <p className={classes.tracText}>TRAC</p>
                <img className={classes.logoImg} src={Logo} alt="Logo"/>
            </Toolbar>
        </AppBar>
    );
};

export default SignInPageNavBar;
