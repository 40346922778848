import React from 'react';
import CompanyTable from "./CompanyTable";
import SearchBar from "./SearchBar";
import TableWrapperSearchBar from "./TableWrapperSearchBar";
import {useSelector} from "react-redux";
import {addTableCompany, deleteCompany} from "../reduxUtils/reduxSetup";

const blankComp = (props) => (<p/>);

const PositionCompanyTable = () => {
    const positionTableData = useSelector(state => state.companiesData.positionTable);

    return (
        <CompanyTable
            columns={[
                { title: 'Competitors', field: 'id',
                    editComponent: props => (
                        // <input
                        //     type="text"
                        //     value={props.value}
                        //     onChange={e => props.onChange(e.target.value)}
                        // />
                        <TableWrapperSearchBar/>
                    )
                },
                { title: 'Position', field: 'position', type: 'numeric', editComponent: blankComp },
                { title: 'Cumulative Capital', field: 'cumulativeCapital', type: 'numeric', editComponent: blankComp },
                { title: 'Valuation', field: 'valuation', type: 'numeric', editComponent: blankComp },
                { title: 'Global Web', field: 'globalWeb', type: 'numeric', editComponent: blankComp },
                { title: 'Web Link', field: 'webLink', type: 'numeric', editComponent: blankComp },
                // {
                //     title: 'Birth Place',
                //     field: 'birthCity',
                //     lookup: { 34: 'London', 63: 'San Francisco' },
                // },
            ]}
            // data={[
            //     { competitor: 'Human Interest',
            //         position: 0.543,
            //         cumulativeCapital: 3,
            //         valuation: 2,
            //         globalWeb: 1,
            //         webLink: 2
            //     },
            //     { competitor: 'Guideline',
            //         position: 0.718,
            //         cumulativeCapital: 2,
            //         valuation: 1,
            //         globalWeb: 2,
            //         webLink: 1
            //     },
            //     { competitor: 'Vestwell',
            //         position: 0.632,
            //         cumulativeCapital: 5,
            //         valuation: 3,
            //         globalWeb: 3,
            //         webLink: 4
            //     },
            //     { competitor: 'ForUsAll',
            //         position: 0.514,
            //         cumulativeCapital: 4,
            //         valuation: 4,
            //         globalWeb: 5,
            //         webLink: 3
            //     },
            //     { competitor: 'Ubiquity',
            //         position: 0.503,
            //         cumulativeCapital: 1,
            //         valuation: 5,
            //         globalWeb: 4,
            //         webLink: 5
            //     },
            // ]}
            data={positionTableData}
            title={"Competitor Analysis"}
            addRowAction={addTableCompany}
            deleteRowAction={deleteCompany}
            onRowClick={(event, rowData) => window.open("http://" + rowData.id, "_blank")}
        />
    );
};

export default PositionCompanyTable;
