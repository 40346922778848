import React from 'react';
import "./composedChart.css";
import {useDispatch} from "react-redux";
import {deleteCompany} from "../../reduxUtils/reduxSetup";

const BarChartGenerator = (props) => {
    const dispatch = useDispatch();

    const onLegendClick = (data, event) => {
        dispatch(deleteCompany({company: data.id}));
    };

    return (
        <div className="wholeChartContainer">
            <div className="subWholeChartContainer">
                <div className="titleAndDropDownContainer">
                    <h4 className="chartTitle"> {props.title} </h4>
                    <div className="dropDown" style={{height: "48px"}}>
                    </div>
                </div>
                <props.Chart
                    rightMultiplier={12}
                    topMargin={20}
                    bottomMargin={120}
                    customLeftMargin={props.customLeftMargin}
                    leftMargin={100}
                    bottomLegendOffset={40}
                    leftLegendOffset={-50}
                    legendTranslateX={10}
                    legendItemWidth={0}
                    legendItemHeight={18}
                    legendSymbolSize={14}
                    legendTextColor={"#333333"}
                    legendItemOpacity={1}
                    colorScheme={"dark2"}
                    onLegendClick={onLegendClick}
                    animate={true}
                />
            </div>
        </div>
    );
};

export default BarChartGenerator;
