import React from 'react';
import TopNavBar from "../components/TopNavBar";
import CompanyNavBar from "../components/CompanyNavBar";
import "./CompetitorsPage.css";
import CompetitorsCompanyTable from "../components/CompetitorsCompanyTable";

const CompetitorsPage = () => {
    return (
        <div className="competitorsPageContainer">
            <div className="competitorsNavBarsContainer">
                <TopNavBar/>
                <CompanyNavBar tabValue={0}/>
            </div>
            <div className="competitorsTableAndChartContainer">
                <div className="competitorsCompanyTable">
                    <CompetitorsCompanyTable/>
                    {/*<MomentumCompanyTable/>*/}
                </div>
                {/*<MomentumcompetitorsLine/>*/}
            </div>
            <div className="competitorsBottomChartContainer">
                {/*<MomentumTrafficLine/>*/}
                {/*/!*<MomentumComposite/>*!/*/}
                {/*/!*<MomentumInfluencerPostsLine/>*!/*/}
            </div>
        </div>
    );
};

export default CompetitorsPage;
