import React from 'react';

import { ResponsiveLine } from '@nivo/line';
import chartTheme from "../../themes/nivoChartsTheme";
import LineChartGenerator from "../LineChartGenerator";
import {useDispatch, useSelector} from "react-redux";
import {updateValuationTimeFrame} from "../../../reduxUtils/reduxSetup";
import {timeFrameSelect} from "../chartUtils";

let sampleData = [
    {
        id: "sample",
        data: [
            { x: '2018-01-01', y: null },
            { x: '2018-02-01', y: null },
            { x: '2018-03-01', y: null },
            { x: '2018-04-01', y: null },
            { x: '2018-05-01', y: null },
            // { x: '2018-06-01', y: 90 },
            // { x: '2018-07-01', y: 110 },
            // { x: '2018-08-01', y: 80 },
        ],
    },
];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
const PositionValuationLineChart = (props) => {
    // let data=[
    //     {
    //         id: 'Human Interest',
    //         data: [
    //             { x: '2013-01-01', y: 0 },
    //             { x: '2014-02-01', y: 0},
    //             { x: '2015-01-01', y: 0 },
    //             { x: '2016-01-01', y: 0 },
    //             { x: '2017-01-01', y: 0 },
    //             { x: '2018-01-01', y: 5 },
    //             { x: '2019-01-01', y: 40 },
    //             { x: '2020-01-01', y: 220 },
    //         ],
    //     },
    //     {
    //         id: 'Guideline',
    //         data: [
    //             { x: '2013-01-01', y: 0 },
    //             { x: '2014-02-01', y: 0},
    //             { x: '2015-01-01', y: 0 },
    //             { x: '2016-01-01', y: 0 },
    //             { x: '2017-01-01', y: 4 },
    //             { x: '2018-01-01', y: 50 },
    //             { x: '2019-01-01', y: 150 },
    //             { x: '2020-01-01', y: 250 },
    //         ],
    //     },
    //     {
    //         id: 'Vestwell',
    //         data: [
    //             { x: '2013-01-01', y: 0 },
    //             { x: '2014-02-01', y: 0},
    //             { x: '2015-01-01', y: 6 },
    //             { x: '2016-01-01', y: 30 },
    //             { x: '2017-01-01', y: 90 },
    //             { x: '2018-01-01', y: 120 },
    //             { x: '2019-01-01', y: 150 },
    //             { x: '2020-01-01', y: 200 },
    //         ],
    //     },
    //     {
    //         id: 'ForUsAll',
    //         data: [
    //             { x: '2013-01-01', y: 0 },
    //             { x: '2014-02-01', y: 0},
    //             { x: '2015-01-01', y: 0 },
    //             { x: '2016-01-01', y: 0 },
    //             { x: '2017-01-01', y: 0 },
    //             { x: '2018-01-01', y: 10 },
    //             { x: '2019-01-01', y: 30 },
    //             { x: '2020-01-01', y: 60 },
    //         ],
    //     },
    //     {
    //         id: 'Ubiquity',
    //         data: [
    //             { x: '2013-01-01', y: 10 },
    //             { x: '2014-02-01', y: 20},
    //             { x: '2015-01-01', y: 50 },
    //             { x: '2016-01-01', y: 100 },
    //             { x: '2017-01-01', y: 110 },
    //             { x: '2018-01-01', y: 110 },
    //             { x: '2019-01-01', y: 110 },
    //             { x: '2020-01-01', y: 170 },
    //         ],
    //     },
    // ];
    let data = useSelector(state => state.companiesData.positionValuation);
    const dataIsEmpty = data.length === 0;
    data = dataIsEmpty ? sampleData : data;
    let dataForLegends = dataIsEmpty ? [] : data;
    const timeframe = useSelector(state => state.timeFrames.valuation);

    return (
        <ResponsiveLine
            data={timeFrameSelect(data, timeframe, "M")}
            margin={{top: 20,
                right: props.rightMultiplier * Math.max.apply(Math, data.map(function (o) {
                    return o.id.length;
                })),
                bottom: props.bottomMargin,
                left: props.leftMargin
            }}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={dataIsEmpty ? props.emptyYScale : {
                type: 'linear',
                stacked: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                format: '%b %y',
                // tickValues: 'every 1 year',
                tickValues: 4,
                legend: 'Time',
                legendOffset: props.bottomLegendOffset,
                legendPosition: 'middle'
            }}
            axisLeft={{
                format: ',.2r',
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Valuation (Millions)',
                legendOffset: props.leftLegendOffset,
                legendPosition: 'middle'
            }}
            theme={chartTheme}
            colors={{scheme: props.colorScheme}}
            pointSize={props.pointSize}
            pointColor={{theme: 'background'}}
            pointBorderWidth={2}
            pointBorderColor={{from: 'serieColor'}}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={props.linePointTooltip}
            animate={props.animate}
            legends={[
                {
                    data: dataForLegends,
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: props.legendTranslateX,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: props.legendItemWidth,
                    itemHeight: props.legendItemHeight,
                    itemOpacity: props.legendItemOpacity,
                    symbolSize: props.legendSymbolSize,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    itemTextColor: props.legendTextColor,
                    onClick: props.onLegendClick,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />);
};

const PositionValuationLine = () => {
    const dispatch = useDispatch();
    const valuationTimeFrame = useSelector((state) => state.timeFrames.valuation);

    const onChange = (e) => {
        dispatch(updateValuationTimeFrame({timeFrame: e.target.value}));
    };

    return (
        <LineChartGenerator
            id={"positionValuationChart"}
            title={"Valuation"}
            value={valuationTimeFrame}
            onChange={onChange}
            Chart={PositionValuationLineChart}/>
    );
};

export default PositionValuationLine;
