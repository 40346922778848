import React from 'react';
import TopNavBar from "../components/TopNavBar";
import CompanyNavBar from "../components/CompanyNavBar";
import "./TrafficPage.css";
import MomentumTrafficLine from "../components/charts/momentum/Momentum-TrafficLine";
import MomentumCompanyTable from "../components/MomentumCompanyTable";
import MomentumComposite from "../components/charts/momentum/Momentum-Composite";

const TrafficPage = () => {
    return (
        <div className="trafficPageContainer">
            <div className="trafficNavBarsContainer">
                <TopNavBar/>
                <CompanyNavBar tabValue={1}/>
            </div>
            <div className="trafficTableAndChartContainer">
                <div className="trafficCompanyTable">
                    <MomentumCompanyTable/>
                </div>
                {/*<MomentumTrafficLine/>*/}
            </div>
            <div className="trafficBottomChartContainer">
                <MomentumTrafficLine/>
                {/*<MomentumComposite/>*/}
                {/*<MomentumInfluencerPostsLine/>*/}
            </div>
        </div>
    );
};

export default TrafficPage;
