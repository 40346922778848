import React from "react";
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {rootReducer} from "../reduxUtils/reduxSetup";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

let initialCompaniesData = {
    // momentumTraffic: [
    //     {
    //         id: "sample",
    //         data: [
    //             { x: '2018-01-01', y: 60 },
    //             { x: '2018-02-01', y: 70 },
    //             { x: '2018-03-01', y: 90 },
    //             { x: '2018-04-01', y: 60 },
    //             { x: '2018-05-01', y: 100 },
    //             { x: '2018-06-01', y: 90 },
    //             { x: '2018-07-01', y: 110 },
    //             { x: '2018-08-01', y: 80 },
    //         ],
    //     },
    // ],
    // momentumInfluencerPosts: [
    //     {
    //         id: "sample",
    //         data: [
    //             { x: '2018-01-01', y: 60 },
    //             { x: '2018-02-01', y: 70 },
    //             { x: '2018-03-01', y: 90 },
    //             { x: '2018-04-01', y: 60 },
    //             { x: '2018-05-01', y: 100 },
    //             { x: '2018-06-01', y: 90 },
    //             { x: '2018-07-01', y: 110 },
    //             { x: '2018-08-01', y: 80 },
    //         ],
    //     },
    // ],
    // momentumComposite: [
    //     {
    //         id: 'Sample Actual',
    //         data: [
    //             { x: '2019-10-01', y: 40 },
    //             { x: '2019-11-01', y: 50},
    //             { x: '2019-12-01', y: 65 },
    //             { x: '2020-01-01', y: 90 },
    //             { x: '2020-02-01', y: 120 },
    //             { x: '2020-03-01', y: 160 },
    //             { x: '2020-04-01', y: 130 },
    //             { x: '2020-05-01', y: 170 },
    //         ],
    //         label: "sample"
    //     },
    //     {
    //         id: 'Sample Predicted',
    //         data: [
    //             { x: '2020-05-01', y: 170 },
    //             { x: '2020-06-01', y: 110 },
    //             { x: '2020-07-01', y: 100 },
    //             { x: '2020-08-01', y: 110 },
    //             { x: '2020-09-01', y: 90 },
    //             { x: '2020-10-01', y: 130 },
    //             { x: '2020-11-01', y: 180},
    //             { x: '2020-12-01', y: 140 },
    //             { x: '2021-01-01', y: 180 },
    //         ],
    //         label: "sample"
    //     },
    // ],
    // positionEmployees: [
    //     {
    //         id: "sample",
    //         data: [
    //             { x: '2018-01-01', y: 60 },
    //             { x: '2018-02-01', y: 70 },
    //             { x: '2018-03-01', y: 90 },
    //             { x: '2018-04-01', y: 60 },
    //             { x: '2018-05-01', y: 100 },
    //             { x: '2018-06-01', y: 90 },
    //             { x: '2018-07-01', y: 110 },
    //             { x: '2018-08-01', y: 80 },
    //         ],
    //     },
    // ],
    // positionValuation: [
    //     {
    //         id: "sample",
    //         data: [
    //             { x: '2018-01-01', y: 60 },
    //             { x: '2018-02-01', y: 70 },
    //             { x: '2018-03-01', y: 90 },
    //             { x: '2018-04-01', y: 60 },
    //             { x: '2018-05-01', y: 100 },
    //             { x: '2018-06-01', y: 90 },
    //             { x: '2018-07-01', y: 110 },
    //             { x: '2018-08-01', y: 80 },
    //         ],
    //     },
    // ],
    // positionCumRaised: [
    //     {
    //         "id": "Sample",
    //         "Cumulative Raised": 150,
    //     },
    // ],
    momentumTraffic: [],
    momentumInfluencerPosts: [],
    momentumComposite: [],
    positionEmployees: [],
    positionValuation: [],
    positionCumRaised: [],
    momentumTable: [],
    positionTable: [],
};

let initialState = {
    company: "Company",
    companyId: "Company Id",
    overallSearchTerm: "",
    tableSearchTerm: "",
    loading: false,
    shouldLoadDefault: true,
    companies: [],
    autocompleteCompanies: [],
    companiesData: initialCompaniesData,
    dbCompetitors: [],
    timeFrames: {
        webTraffic: 180,
        compositeMomentum: 180,
        influencerPosts: 180,
        employees: 180,
        valuation: 180,
    },
    snackbars: [],
};

const persistConfig = {
    key: 'root',
    version: 1,
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    }),
    preloadedState: initialState,
});

let persistor = persistStore(store);

export {persistor};
export default store;


