const chartTheme = {
    fontSize: "20px",
    background: "transparent",
    textColor: '#333333',
    axis: {
        fontSize: "0.5em",
        tickColor: "#eee",
        ticks: {
            line: {
                stroke: "#555555"
            },
            text: {
                fontSize: "0.6em",
                fill: "#333333"
            }
        },
        legend: {
            text: {
                fontSize: "0.6em",
                fill: "#333333"
            }
        }
    },
    grid: {
        line: {
            stroke: "#aaaaaa"
        }
    },
    legends: {
        text: {
            fontSize: "0.6em",
            fill: "#333333"
        }
    },
    tooltip: {
        container: {
            background: 'white',
            color: 'black',
            fontSize: '0.6em',
            borderRadius: '2px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: '5px 9px',
        },
    },
};

export default chartTheme;
