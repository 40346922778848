/* eslint-disable no-use-before-define */
import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import grey from "@material-ui/core/colors/grey";
import {useSelector, useDispatch} from "react-redux";
import {addAutocompleteCompanies} from "../reduxUtils/reduxSetup";
import {updateOverallSearchTerm} from "../reduxUtils/reduxSetup";
import {addOverallCompany} from "../reduxUtils/reduxSetup";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// const renderOptions = () => {
//     return companies.map((company) => company.name);
// };

// const onSubmit = (e) => {
//     e.preventDefault();
//     alert("You searched up a company.");
//     console.log(e);
// };

export default function SearchBar(props) {

    let dispatch = useDispatch();
    const autocompleteCompanies = useSelector(state => state.autocompleteCompanies);
    const overallSearchTerm = useSelector(state => state.overallSearchTerm);

    useEffect(() => {
        // Fetch autcomplete companies
        dispatch(addAutocompleteCompanies());
    }, []);

    const renderOptions = () => {
        // return companies.map((company) => company.name);
        // if (autocompleteCompanies.length < 1 || autocompleteCompanies[0].site === "Autocompletion Unavailable") {
        //     dispatch(addAutocompleteCompanies());
        // }
        return autocompleteCompanies.map((company) => company.site);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(addOverallCompany(overallSearchTerm));
    };

    const onChange = (e, value) => (
        dispatch(updateOverallSearchTerm({overallSearchTerm: value}))
    );



    return (
        <div style={{ width: props.width || "12em", paddingTop: props.paddingTop || "0px"}}>
            <Autocomplete
                freeSolo={true}
                disableClearable={false}
                options={renderOptions() || []}
                filterOptions={createFilterOptions({limit: 20})}
                onChange={props.onChange || onChange}
                renderInput={(params) => (
                    <form onSubmit={props.onSubmit || onSubmit}>
                    <TextField
                        {...params}
                        // value={props.value}
                        onChange={props.onTextFieldChange}
                        placeholder={props.placeholder || "Search..."}
                        InputProps={{
                            ...params.InputProps,
                            // type: 'search',
                            startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize={props.iconSize || "default"} style={{color: grey[700]}} />
                            </InputAdornment>
                            ),
                            style: {
                                color: grey[900],
                                fontSize: props.fontSize || "0.8em",
                            }
                        }}
                    />
                    </form>
                )}
            />
        </div>
    );
}

const companies = [
    { name: 'Human Interest'},
    { name: 'Guideline'},
    { name: 'Amazon'},
    { name: 'Apple'},
    { name: 'Adobe'},
    { name: 'Autodesk'},
    { name: 'Vestwell'},
    { name: "For Us All"},
    { name: "Facebook"},
    { name: "Microsoft"},
];
