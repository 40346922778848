import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { categoricalColorSchemes } from '@nivo/colors';
import chartTheme from "../../themes/nivoChartsTheme";
import BarChartGenerator from "../BarChartGenerator";
import {useSelector} from "react-redux";

const { dark2 } = categoricalColorSchemes;

let sampleData = [
    {
        "company": "Sample",
        "Cumulative Raised": null,
    },
];
// make sure parent container have a defined height when using
// responsive component
const PositionCumRaisedBarChart = (props) => {
    // let data = [
    //     {
    //         "id": "Human Interest",
    //         "Cumulative Raised": 225,
    //     },
    //     {
    //         "id": "Guideline",
    //         "Cumulative Raised": 250,
    //     },
    //     {
    //         "id": "Vestwell",
    //         "Cumulative Raised": 140,
    //     },
    //     {
    //         "id": "For Us All",
    //         "Cumulative Raised": 80,
    //     },
    //     {
    //         "id": "Ubiquity",
    //         "Cumulative Raised": 100,
    //     },
    // ];
    let data = useSelector(state => state.companiesData.positionCumRaised);
    const dataIsEmpty = data.length === 0;
    data = dataIsEmpty ? sampleData : data;
    data = data.map((item, index) => ({...item, color: dark2[index % dark2.length]}));
    let dataForLegends = dataIsEmpty ? [] : data;
    return (
        <ResponsiveBar
            data={data}
            keys={['Cumulative Raised']}
            indexBy="company"
            margin={{top: 20,
                right: props.rightMultiplier * Math.max.apply(Math, data.map(function (o) {
                    return o.company.length;
                })),
                bottom: props.bottomMargin,
                left: props.customLeftMargin || props.leftMargin
            }}
            minValue={dataIsEmpty ? 0 : "auto"}
            maxValue={dataIsEmpty ? 100 : "auto"}
            padding={0.3}
            groupMode="grouped"
            colors={d => d.data.color}
            borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickRotation: 0,
                legend: 'Companies',
                legendPosition: 'middle',
                legendOffset: props.bottomLegendOffset,
            }}
            axisLeft={{
                format: ',.2r',
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Cumulative Raised',
                legendPosition: 'middle',
                legendOffset: props.leftLegendOffset,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
            theme={chartTheme}
            legends={[
                {
                    dataFrom: 'indexes',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: props.legendTranslateX,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemWidth: props.legendItemWidth,
                    itemHeight: props.legendItemHeight,
                    itemDirection: 'left-to-right',
                    itemOpacity: props.legendItemOpacity,
                    symbolSize: props.legendSymbolSize,
                    itemTextColor: props.legendTextColor,
                    onClick: props.onLegendClick,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            animate={props.animate}
            motionStiffness={90}
            motionDamping={15}
        />);
};

const PositionCumRaisedBar = () => {
    return (
        <BarChartGenerator
            title={"Cumulative Raised"}
            Chart={PositionCumRaisedBarChart}
            customLeftMargin={70}
        />
    );
};


export default PositionCumRaisedBar;
