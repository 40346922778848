import React from 'react';
import TopNavBar from "../components/TopNavBar";
import CompanyNavBar from "../components/CompanyNavBar";
import "./PositionPage.css";
import PositionCompanyTable from "../components/PositionCompanyTable";
import PositionEmployeesLine from "../components/charts/position/PositionEmployeesLine";
import PositionValuationLine from "../components/charts/position/PositionValuationLine";
import Skeleton from "@material-ui/lab/Skeleton";
import PositionCumRaisedBar from "../components/charts/position/PositionCumRaisedBar";

const PositionPage = () => {
    return (
        <div className="momentumPageContainer">
            <div className="navBarsContainer">
                <TopNavBar/>
                <CompanyNavBar tabValue={2}/>
            </div>
            <div className="tableAndChartContainer">
                <div className="companyTable">
                    <PositionCompanyTable/>
                </div>
                {/*<Skeleton>*/}
                {/*    <PositionEmployeesLine/>*/}
                {/*</Skeleton>*/}
                <Skeleton variant="rect" animation={false} width={"100%"} height={"80%"}
                          style={{marginLeft: "5%", marginTop: "1%", borderRadius: "20px"}}
                />
            </div>
            <div className="twoChartsContainer">
                <PositionCumRaisedBar/>
                <PositionValuationLine/>
            </div>
        </div>
    );
};

export default PositionPage;
