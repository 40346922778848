import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import {toggleLoading} from "../reduxUtils/reduxSetup";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Loader() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const loading = useSelector(store => store.loading);

    const handleClose = () => {
        dispatch(toggleLoading({loading: false}))
    };

    return (
        <Backdrop className={classes.backdrop} invisible={false} open={loading} onClick={handleClose}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
