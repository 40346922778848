import moment from "moment";

/* Given an array COMPOBJDATA of (x,y) datapoints and a TIMEFRAME
returns a truncated array of data based on the timeframe.
 */
const generateSlicedProjectedData = (compObjData, lastProjectedMonth, timeframe) => {

    let foundDesiredProjectedEndMonth = false;
    let result = null;
    result = compObjData.filter(dataPoint => {
        if (foundDesiredProjectedEndMonth) {
            return false;
        }
        foundDesiredProjectedEndMonth = (dataPoint.x === lastProjectedMonth);
        return true;
    });
    return (
        result
    );
};

/* Modifies the data attribute of each company object located in the
ARRCOMPANYOBJECTS array. Modifies the data attribute based on the FIRSTACTUALMONTH
of the TIMEFRAME.
 */
const sliceActualData = (arrCompanyObjects, firstActualMonth, timeframe) => {
    let lastProjectedMonth = moment().add(Math.ceil(timeframe / 2), 'months'
    ).startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0
    ).format("YYYY-MM-DD HH:mm:ss");

    arrCompanyObjects.forEach(companyObj => {
        let foundDesiredActualStartMonth = false;
        let slicedActualData = companyObj.data.filter(dataPoint => {
            if (foundDesiredActualStartMonth) {
                return true;
            }
            foundDesiredActualStartMonth = (dataPoint.x === firstActualMonth);
            return false;
        });

        /* The actual month date predates the earliest actual data for this company */
        if (slicedActualData.length === 0) {
            slicedActualData = companyObj.data;
        }

        /* This will work even for data that is only actual since no id would inclued Projected then */
        companyObj.data = companyObj.id.includes("Projected") ?
            generateSlicedProjectedData(companyObj.data, lastProjectedMonth, timeframe) :
            slicedActualData;
        // companyObj.data.slice(Math.max(Math.floor(companyObj.data.length - (timeframe / 2)), 0))
    });
};

export const timeFrameSelect = (data, timeframe, dataTimeInterval, includesPredicted = false) => {
    // remember that since DATA is the same object as stored in our redux state,
    // it is immutable.
    switch (dataTimeInterval) {
        case "D":
            break;
        case "M":
            timeframe = Math.round(timeframe / 30);
            break;
        case "Y":
            timeframe = Math.round((timeframe / 365) + 1);
            break;
        default:
            break;
    }

    // create copy of data (consisting of company objects) array so that
    // we can mutate the first order elems
    data = data.map(elem => Object.assign({}, elem));

    //create a copy of the 'data' field for each companyObj; truncate it to the necessary value in order to reflect our select
    // time frame. If the data includes predicted data, we want to take half of the actual and half from the predicted.
    if (includesPredicted) {
        let firstActualMonth = moment().subtract(Math.ceil(timeframe / 2) + 1, 'months'
        ).startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0
        ).format("YYYY-MM-DD HH:mm:ss");

        sliceActualData(data, firstActualMonth, timeframe);
        //
        // data.forEach(companyObj => {
        //     let foundDesiredActualStartMonth = false;
        //     let slicedActualData = companyObj.data.filter(dataPoint => {
        //         if (foundDesiredActualStartMonth) {
        //             return true;
        //         }
        //         foundDesiredActualStartMonth = (dataPoint.x === firstActualMonth);
        //     });
        //
        //     /* The actual month date predates the earliest actual data for this company */
        //     if (slicedActualData.length === 0) {
        //         slicedActualData = companyObj.data;
        //     }
        //
        //     companyObj.data = companyObj.id.includes("Projected") ?
        //         companyObj.data.slice(0, Math.ceil(timeframe / 2)) :
        //         slicedActualData;
        //         // companyObj.data.slice(Math.max(Math.floor(companyObj.data.length - (timeframe / 2)), 0))
        // });
    } else {
        let firstActualMonth = moment().subtract(Math.ceil(timeframe) + 1, 'months'
        ).startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0
        ).format("YYYY-MM-DD HH:mm:ss");

        sliceActualData(data, firstActualMonth, timeframe);

        // data.forEach(companyObj => {
        //     let foundDesiredActualStartMonth = false;
        //     let slicedActualData = companyObj.data.filter(dataPoint => {
        //         if (foundDesiredActualStartMonth) {
        //             return true;
        //         }
        //         foundDesiredActualStartMonth = (dataPoint.x === firstActualMonth);
        //     });
        //
        //     /* The actual month date predates the earliest actual data for this company */
        //     if (slicedActualData.length === 0) {
        //         slicedActualData = companyObj.data;
        //     };
        //
        //     companyObj.data = slicedActualData;
        //
        //
        // });
        // data.forEach(companyObj => companyObj.data = companyObj.data.slice(Math.max(companyObj.data.length - timeframe, 0)));
    }

    return data;
};
