import {createMuiTheme} from "@material-ui/core";

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: "#2E5F92"
        },
        background: {
            paper: "#0E1317"
        }
    },

});

export default darkTheme;
