import React from 'react';
import "./composedChart.css";
import DropDownTimeSelect from "../DropDownTimeSelect";
import LinePointTooltip from "../LinePointTooltip";
import {deleteCompany} from "../../reduxUtils/reduxSetup";
import {useDispatch} from "react-redux";
import fscreen from "fscreen";
import {Maximize2} from "react-feather";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    icon: {
        paddingLeft: "0.6em",
        strokeWidth: 1,
        color: "#333333",
        '&:hover': {
            filter: "opacity(0.5)",
        }
    }
});

const LineChartGenerator = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const onLegendClick = (data, event) => {
        dispatch(deleteCompany({company: data.label}));
    };

    const toggleFullScreen = () => {
        if (fscreen.fullscreenElement === document.getElementById(props.id)) {
            try {
                fscreen.exitFullscreen();
            } catch (e) {
            }
        } else {
            fscreen.requestFullscreen(document.getElementById(props.id));
        }
    };

    return (
        <div id={props.id} className="wholeChartContainer">
            <div className="subWholeChartContainer">
                <div className="titleAndDropDownContainer">
                    <h4 id={props.id+"Title"} className="chartTitle"> {props.title} </h4>
                    <Maximize2 className={classes.icon} size={"1em"} onClick={toggleFullScreen}/>
                    {/*<Button onClick={toggleFullScreen} variant="contained" color="primary">*/}
                    {/*    Primary*/}
                    {/*</Button>*/}
                    <div className="dropDown">
                        <DropDownTimeSelect
                            value={props.value}
                            onChange={props.onChange}
                            id={props.id}
                        />
                    </div>
                </div>
                <props.Chart
                    emptyYScale={{
                        type: 'linear',
                        min: 0,
                        max: 100,
                        stacked: false,
                    }}
                    rightMultiplier={12}
                    bottomMargin={120}
                    customLeftMargin={props.customLeftMargin}
                    leftMargin={100}
                    bottomLegendOffset={40}
                    leftLegendOffset={-70}
                    customLeftLegendOffset={-85}
                    pointSize={10}
                    linePointTooltip={LinePointTooltip}
                    legendTranslateX={160}
                    legendItemWidth={150}
                    legendItemHeight={18}
                    legendSymbolSize={14}
                    legendTextColor={"#333333"}
                    legendItemOpacity={1}
                    colorScheme={"dark2"}
                    onLegendClick={onLegendClick}
                    animate={true}
                />
            </div>
        </div>
    );
};

export default LineChartGenerator;
