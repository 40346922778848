import React from 'react';
import SignInPageNavBar from "../components/SignInPageNavBar";
import LandingPageImage from "../images/LandingPageImage.svg";
import SignInSheet from "../components/SignInSheet";
import "./SignInPage.css";
import {AmplifyContainer, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';

const SignInPage = () => {
    return (
        <div className="signInContainer">
            <SignInPageNavBar className="navbar"/>
            <div className="landingPageImg">
                <img src={LandingPageImage} alt="landingPageImg"/>
            </div>
            <div className="signInSheetContainer">
                <p className="loggingInText">Checking login status in please wait...</p>
                <AmplifyContainer>
                    <AmplifyAuthenticator>
                        <AmplifySignIn hideSignUp={true} slot="sign-in"> </AmplifySignIn>
                    </AmplifyAuthenticator>
                </AmplifyContainer>
                {/* <SignInSheet className="signInSheet"/> */}
            </div>
        </div>
    );
};

export default SignInPage;

