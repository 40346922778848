import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        width: 180,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function DropDownTimeSelect(props) {
    const classes = useStyles();

    const theme = createMuiTheme({
        // palette: {
        //     type: 'dark',
        //     primary: {
        //         main: "#2E5F92"
        //     },
        //     background: {
        //         paper: "#0E1317"
        //     }
        // },
        props: {
            // Name of the component
            MuiPopover: {
                // The default props to change
                container: () => document.getElementById(props.id),
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel>Timeframe</InputLabel>
                <Select
                    value={props.value}
                    onChange={props.onChange}
                    // MenuProps={{ PopoverClasses: {paper: {text: "blue"}} }}
                >
                    <MenuItem value={90}>3 Months</MenuItem>
                    <MenuItem value={180}>6 Months</MenuItem>
                    <MenuItem value={365}>1 year</MenuItem>
                    <MenuItem value={730}>2 years</MenuItem>
                    <MenuItem value={1095}>3 years</MenuItem>
                </Select>
            </FormControl>
        </div>
        </ThemeProvider>
    );
}
