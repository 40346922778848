import React from "react";
import {Component} from 'react';
import darkTheme from "./components/themes/darkTheme";
import SignInPage from "./pages/SignInPage";
import TrafficPage from "./pages/TrafficPage";
import PositionPage from "./pages/PositionPage";
import { ThemeProvider } from "@material-ui/styles";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import Notifier from "./components/Notifier";
import Loader from "./components/Loader";
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';

import Amplify from '@aws-amplify/core';
import { Auth, appendToCognitoUserAgent } from '@aws-amplify/auth';
import CompositeMomentumPage from "./pages/CompositeMomentumPage";
import CompetitorsPage from "./pages/CompetitorsPage";
import jwtDecode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";
import {toggleShouldLoadDefault, toggleLoading, addOverallCompany} from "./reduxUtils/reduxSetup";

// Amplify.configure(awsconfig);
// Auth.configure(awsconfig);

let authConfig = {

    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_jgiffETrI',

    userPoolWebClientId: "3uc52hau526n50p3koqeh3ch0u",
};

Amplify.configure({
    Auth: authConfig
});

Auth.configure(authConfig);


const App = () => {

    const [signedIn, setSignedIn] = React.useState(false);

    const dispatch = useDispatch();
    const shouldLoadDefault = useSelector(store => store.shouldLoadDefault);

    const loadDefaultCompany = async() => {
        try {
            let currentSess = await Auth.currentSession();
            let encodedJwtToken = currentSess.getIdToken().getJwtToken();
            let jwtToken = jwtDecode(encodedJwtToken);
            let defaultCompany = jwtToken["custom:defaultCompany"];
            if (defaultCompany) {
                dispatch(addOverallCompany(defaultCompany));
            }
        } catch (e) {

        }
    };


    React.useEffect(() => {
        // appendToCognitoUserAgent('withAuthenticator');
        // eslint-disable-next-line no-unused-expressions
        async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                if (user) {
                    await loadDefaultCompany();
                    setSignedIn(true);
                }
            } catch (err) {

            }
        };
        return onAuthUIStateChange(authState => {
            if (authState === AuthState.SignedIn) {
                if (shouldLoadDefault) {
                    dispatch(toggleShouldLoadDefault({shouldLoadDefault: false}));
                    loadDefaultCompany();
                }
                setSignedIn(true);
            } else if (authState === AuthState.SignedOut) {
                setSignedIn(false);
            }
        });
    }, []);

    if (!signedIn) {
        return (
            <SignInPage/>
        );
    }

        return (
            // <ThemeProvider theme={darkTheme}>
                <SnackbarProvider>
                <Notifier/>
                <Loader/>
                <Router>
                        <Switch>
                            {/*<Route exact path="/signin" component={SignInPage}/>*/}
                            <Route exact path="/position" component={PositionPage}/>
                            <Route exact path="/competitors" component={CompetitorsPage}/>
                            <Route exact path="/compositeMomentum" component={CompositeMomentumPage}/>
                            <Route path="/" component={TrafficPage}/>
                        </Switch>
                </Router>
                </SnackbarProvider>
            // </ThemeProvider>
        );
}

export default App;
