import React from 'react';
import TopNavBar from "../components/TopNavBar";
import CompanyNavBar from "../components/CompanyNavBar";
import "./CompositeMomentumPage.css";
import MomentumComposite from "../components/charts/momentum/Momentum-Composite";

const CompositeMomentumPage = () => {
    return (
        <div className="compositeMomentumPageContainer">
            <div className="compositeMomentumNavBarsContainer">
                <TopNavBar/>
                <CompanyNavBar tabValue={2}/>
            </div>
            <div className="compositeMomentumBottomChartContainer">
                <MomentumComposite/>
            </div>
        </div>
    );
};

export default CompositeMomentumPage;
