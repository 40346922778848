import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar} from "@material-ui/core";
import CompanyTabs from "./CompanyTabs";
import {useSelector} from "react-redux";
import blueTheme from "./themes/blueTheme";
import {ThemeProvider} from "@material-ui/styles";

const useStyles = makeStyles({
    root: {
        background: 'transparent',
        boxShadow: "none",
        position: "static",
    },
    companyText: {
        fontSize: "1em",
        color: "#2E5F92",
        fontFamily: "Roboto, Sans-Serif",
        fontWeight: "400",
        marginRight: "0.5em",
    },
    tabsContainer: {
        position: "absolute",
        right: "4%",
    },
});

let data = {
    company: "Human Interest",
    companyId: "198462-43",
};

const CompanyNavBar = ({style, tabValue}) => {
    const classes = useStyles();
    const company = useSelector(state => state.company);
    const companyId = useSelector(state => state.companyId);

    return (
        <ThemeProvider theme={blueTheme}>
            <AppBar className={classes.root} style={style}>
                <Toolbar variant={"dense"}>
                        <h2 className={classes.companyText}>{company}</h2>
                        <h2 className={classes.companyText}>{"[".concat(companyId).concat("]")}</h2>
                        <div className={classes.tabsContainer}>
                            <CompanyTabs tabValue={tabValue}/>
                        </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
};
export default CompanyNavBar;
