import React from 'react';
import CompanyTable from "./CompanyTable";
import TableWrapperSearchBar from "./TableWrapperSearchBar";
import {useSelector} from "react-redux";
import {addTableCompany, deleteCompany} from "../reduxUtils/reduxSetup";


const blankComp = (props) => (<p/>);

const morphNegatives = (rowData, propName) => {
    let value = rowData[propName];
    if (value < 0) {
        return "("+Math.abs(value)+"%)";
    } else {
        return value+"%";
    }
};

const MomentumCompanyTable = () => {
    const momentumTableData = useSelector(state => state.companiesData.momentumTable);

    return (
        <CompanyTable
            columns={[
                { title: 'Competitor', field: 'id',
                    editComponent: props => (
                        // <input
                        //     type="text"
                        //     value={props.value}
                        //     onChange={e => props.onChange(e.target.value)}
                        // />
                        <TableWrapperSearchBar/>
                    )
                },
                { title: 'Rank', field: 'momentum', type: 'numeric', editComponent: blankComp},
                // { title: 'Capital Formation', field: 'capitalFormation', type: 'numeric', editComponent: blankComp},
                { title: 'YoY Traffic', field: 'YOYTraffic', type: 'numeric',
                    editComponent: blankComp,
                    render: (rowData) => morphNegatives(rowData, "YOYTraffic")
                },
                { title: 'QoQ Traffic', field: 'QTRTraffic', type: 'numeric',
                    editComponent: blankComp,
                    render: (rowData) => morphNegatives(rowData, "QTRTraffic")
                },
                // { title: 'Industry Influencer', field: 'industryInfluencer', type: 'numeric', editComponent: blankComp },
                // {
                //     title: 'Birth Place',
                //     field: 'birthCity',
                //     lookup: { 34: 'London', 63: 'San Francisco' },
                // },
            ]}
            // data={[
            //     { competitor: 'Human Interest',
            //         momentum: 0.437,
            //         capitalFormation: 3,
            //         YOYTraffic: 2,
            //         QTRTraffic: 1,
            //         industryInfluencer: 2
            //     },
            //     { competitor: 'Guideline',
            //         momentum: 0.381,
            //         capitalFormation: 2,
            //         YOYTraffic: 1,
            //         QTRTraffic: 2,
            //         industryInfluencer: 1
            //     },
            //     { competitor: 'Vestwell',
            //         momentum: 0.353,
            //         capitalFormation: 5,
            //         YOYTraffic: 3,
            //         QTRTraffic: 3,
            //         industryInfluencer: 4
            //     },
            //     { competitor: 'ForUsAll',
            //         momentum: 0.299,
            //         capitalFormation: 4,
            //         YOYTraffic: 4,
            //         QTRTraffic: 5,
            //         industryInfluencer: 3
            //     },
            //     { competitor: 'Ubiquity',
            //         momentum: 0.276,
            //         capitalFormation: 1,
            //         YOYTraffic: 5,
            //         QTRTraffic: 4,
            //         industryInfluencer: 5
            //     },
            // ]}
            data={momentumTableData}
            title={"Competitor Analysis, US"}
            addRowAction={addTableCompany}
            deleteRowAction={deleteCompany}
            onRowClick={(event, rowData) => window.open("http://" + rowData.id, "_blank")}
        />
    );
};

export default MomentumCompanyTable;
