import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';

const blueTheme = createMuiTheme({
    palette: {
        primary: blueGrey,
        secondary: blueGrey,
    },
    status: {
        danger: 'orange',
    },
});

export default blueTheme;
