import React, { memo } from 'react';
import { BasicTooltip } from '@nivo/tooltip';
import {format} from "d3-format";


const LinePointTooltip = ({ point }) => {
    return (
        <BasicTooltip
            id={
                <span>
                    <strong>{point.serieId}</strong>,
                    x: <strong>{point.data.xFormatted}</strong>,
                    y:{' '} <strong>{format(".3s")(point.data.yFormatted)}</strong>
                </span>
            }
            enableChip={true}
            color={point.serieColor}
        />
    )
};

export default memo(LinePointTooltip);
