import React from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import darkTheme from "./themes/darkTheme";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import {addTableCompany, deleteCompany} from "../reduxUtils/reduxSetup";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

let theme = createMuiTheme({
    // palette: {
    //     ...darkTheme.palette,
    //     background: {
    //         paper: "#263238"
    //     }
    // },
    typography: {
        fontSize: 12,
    },
    overrides: {
        MuiTableCell: {
            root: {
                padding: "0",
                paddingRight: "0.8em"
            },
            paddingCheckbox: {
                paddingRight: 4
            }
        }
    }

});

function CompanyTable(props) {
    // const [state, setState] = React.useState({
    //     columns: props.columns,
    //     data: props.data,
    // });

    const dispatch = useDispatch();

    return (
        <ThemeProvider theme={theme}>
            <MaterialTable
                icons={tableIcons}
                title={props.title}
                columns={props.columns}
                style={{borderRadius: "20px",}}
                options={{
                    pageSizeOptions : [5, 10, 20],
                    rowStyle: {
                        fontSize: 12,
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
                    }
                }}
                localization={{
                    toolbar: {
                        searchTooltip: 'Filter Competitors',
                        searchPlaceholder: 'Filter Competitors'
                    }
                }}
                onRowClick={props.onRowClick}
                data={props.data.map(elem => Object.assign({}, elem))}
                // actions={[
                //     {
                //         icon: 'save',
                //         tooltip: 'Save User',
                //         onClick: (event, rowData) => {
                //
                //         }
                //     }
                // ]}
                editable={{
                    onRowAdd: (newData) =>

                        new Promise((resolve) => {
                            dispatch(props.addRowAction());
                            resolve();
                            // setTimeout(() => {
                            //     resolve();
                            // }, 400);
                        }),
                    // onRowUpdate: (newData, oldData) =>
                    //     new Promise((resolve) => {
                    //         setTimeout(() => {
                    //             resolve();
                    //             if (oldData) {
                    //                 setState((prevState) => {
                    //                     const data = [...prevState.data];
                    //                     data[data.indexOf(oldData)] = newData;
                    //                     return { ...prevState, data };
                    //                 });
                    //             }
                    //         }, 400);
                    //     }),
                    onRowDelete: (oldData) =>

                        new Promise((resolve) => {
                            dispatch(props.deleteRowAction({company: oldData.id || oldData.site}));
                            resolve();
                            // setTimeout(() => {
                            //     resolve();
                            // }, 400);
                        }),
                }}
            />
        </ThemeProvider>
    );
}

// const CompanyTable = () => {
//     return (
//         <div style={{borderRadius: "20px", overflow: "hidden", width: "80%", marginLeft: "100px", marginTop: "20px"}}>
//             <Table/>
//         </div>
//     );
// };

export default CompanyTable;


