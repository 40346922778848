import React from "react";
import SearchBar from "./SearchBar";
import {useDispatch} from "react-redux";
import {updateTableSearchTerm} from "../reduxUtils/reduxSetup";

const TableWrapperSearchBar = (props) => {
    let dispatch = useDispatch();

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const onChange = (e, value) => (
        dispatch(updateTableSearchTerm({tableSearchTerm: value}))
    );

    const onTextFieldChange = (e) => {
        dispatch(updateTableSearchTerm({tableSearchTerm: e.target.value}))
    };

    return (
        <SearchBar
            onSubmit={onSubmit}
            width={"12em"}
            // value={props.value}
            onChange={onChange}
            onTextFieldChange={onTextFieldChange}
            paddingTop={"5px"}
            iconSize={"small"}
            fontSize={"1.1em"}
            placeholder={"Add Competitor"}
        />
    );
};

export default TableWrapperSearchBar;
