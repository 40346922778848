import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import { Auth } from '@aws-amplify/auth';
import React from "react";

/* Our reduxUtils to synchronously update our state */
const updateCompanyF = (state, action) => (
    {
        ...state,
        company: action.payload.company,
        companyId: action.payload.companyId
    }
);

const updateOverallSearchTermF = (state, action) => (
    {
        ...state,
        overallSearchTerm: action.payload.overallSearchTerm,
    }
);

const updateTableSearchTermF = (state, action) => (
    {
        ...state,
        tableSearchTerm: action.payload.tableSearchTerm,
    }
);

const updateCompaniesF = (state, action) => (
    {
        ...state,
        companies: action.payload.companies
    }
);

//populate companies for our database table
const updateDBCompetitorsF = (state, action) => (
    {
        ...state,
        dbCompetitors: action.payload.dbCompetitors
    }
);

const updateSingleDBCompetitorsF = (state, action) => (
    {
        ...state,
        dbCompetitors: state.dbCompetitors.concat(action.payload.dbCompetitor)
    }
);

const deleteDBCompetitorsF = (state, action) => {
    state.dbCompetitors = state.dbCompetitors.filter(item => item.site !== action.payload.competitor);
};

const updateCompaniesFromTableF = (state, action) => (
    {
        ...state,
        companies: state.companies.concat(action.payload.companies)
    }
);

const deleteCompanyF = (state, action) => {
    state.companies = state.companies.filter(item => item !== action.payload.company);
    Object.keys(state.companiesData).forEach((key, index) => {
        state.companiesData[key] = state.companiesData[key].filter(
            companyData => companyData.id !== action.payload.company && companyData.label !== action.payload.company);
        // state.companiesData[key] = state.companiesData[key].filter(companyData => companyData.label !== action.payload.company);
    });
};

const updateAutocompleteCompaniesF = (state, action) => (
    {
        ...state,
        autocompleteCompanies: action.payload
    }
);

const updateAllCompaniesDataF = (state, action) => (
    {
        ...state,
        companiesData: action.payload.companiesData,
    }
);

const updateTableCompaniesDataF = (state, action) => {
    if (Object.entries(state.companiesData).length === 0) {
        state.companiesData = action.payload.companyData;
    } else {
        Object.keys(action.payload.companyData).forEach((key, index) => {
            state.companiesData[key] = state.companiesData[key].concat(action.payload.companyData[key]);
        });
    }
};

const updateWebTrafficTimeFrameF = (state, action) => {
    state.timeFrames.webTraffic = action.payload.timeFrame;
};

const updateCompositeMomentumTimeFrameF = (state, action) => {
    state.timeFrames.compositeMomentum = action.payload.timeFrame;
};

const updateInfluencerPostsTimeFrameF = (state, action) => {
    state.timeFrames.influencerPosts = action.payload.timeFrame;
};

const updateEmployeesTimeFrameF = (state, action) => {
    state.timeFrames.employees = action.payload.timeFrame;
};

const updateValuationTimeFrameF = (state, action) => {
    state.timeFrames.valuation = action.payload.timeFrame;
};

const pushSnackbarF = (state, action) => {
    action.payload.snackbar["key"] = action.payload.snackbar["key"] || Date.now() + Math.random();
    state.snackbars.push(action.payload.snackbar);
};

const removeSnackbarF = (state, action) => {
    state.snackbars = state.snackbars.filter(snackbar => snackbar.key !== action.payload.key)
};

const toggleLoadingF = (state, action) => (
    {
        ...state,
        loading: action.payload.loading,
    }
);

const toggleShouldLoadDefaultF = (state, action) => (
    {
        ...state,
        shouldLoadDefault: action.payload.shouldLoadDefault
    }
);

/* Redux Toolkit utility method to auto-create action types, creators, and reducer. */
const dataSlice = createSlice({
    name: 'data',
    initialState: {},
    reducers: {
        "updateCompany": updateCompanyF,
        "updateOverallSearchTerm": updateOverallSearchTermF,
        "updateTableSearchTerm": updateTableSearchTermF,
        "updateCompanies": updateCompaniesF,
        "updateDBCompetitors": updateDBCompetitorsF,
        "updateSingleDBCompetitors": updateSingleDBCompetitorsF,
        "deleteDBCompetitors": deleteDBCompetitorsF,
        "updateCompaniesFromTable": updateCompaniesFromTableF,
        "deleteCompany": deleteCompanyF,
        "updateAutocompleteCompanies": updateAutocompleteCompaniesF,
        "updateAllCompaniesData": updateAllCompaniesDataF,
        "updateTableCompaniesData": updateTableCompaniesDataF,
        "updateWebTrafficTimeFrame": updateWebTrafficTimeFrameF,
        "updateCompositeMomentumTimeFrame": updateCompositeMomentumTimeFrameF,
        "updateInfluencerPostsTimeFrame": updateInfluencerPostsTimeFrameF,
        "updateEmployeesTimeFrame": updateEmployeesTimeFrameF,
        "updateValuationTimeFrame": updateValuationTimeFrameF,
        "pushSnackbar": pushSnackbarF,
        "removeSnackbar": removeSnackbarF,
        "toggleLoading": toggleLoadingF,
        "toggleShouldLoadDefault": toggleShouldLoadDefaultF
    }
});




/* Given an object containing a data array and a lowerbound, filters the data array such that
all y values are <= lowerbound. Returns the original object.
 */
const filterLowerBound = (companyDataObj, lowerBound) => {
    companyDataObj.data.forEach(dataPoint => dataPoint.y = (dataPoint.y < lowerBound) ? dataPoint.y : lowerBound);
    return companyDataObj;
};

/* Error handling for fetching company data over the network. Takes in the
fetchedCompanyData object returned by axios from fetchCompanyData. Returns
true if there are no errors, else false.
 */
const handleErrors = (fetchedCompanyData, dispatch, checkForContent=true) => {

    if (fetchedCompanyData.fetchError === "Auth") {
        dispatch(dataSlice.actions.pushSnackbar({
            snackbar: {
                message: "Session Expired: Please log out and log back in.",
                options: {
                    variant: "error",
                    autoHideDuration: 6000,
                }
            }
        }));
        return false;
    }

    if (fetchedCompanyData.fetchError === "Network") {
        dispatch(dataSlice.actions.pushSnackbar({
            snackbar: {
                message: "Connection Timed Out: Please try again later.",
                options: {
                    variant: "error",
                    autoHideDuration: 6000,
                }
            }
        }));
        return false;
    }

    /* If the server states the content is not available, push a snackbar with the
server's message.
 */
    if (!fetchedCompanyData.data.status && checkForContent) {
        dispatch(dataSlice.actions.pushSnackbar({
            snackbar: {
                message: fetchedCompanyData.data.message,
                options: {
                    variant: "error",
                    autoHideDuration: 6000,
                }
            }
        }));
        return false;
    }

    if (!(200 <= fetchedCompanyData.status <= 299)) {
        dispatch(dataSlice.actions.pushSnackbar({
            snackbar: {
                message: "Network Error, please try again later.",
                options: {
                    variant: "error",
                    autoHideDuration: 6000,
                }
            }
        }));
        //handle more nuanced case here
        // check if network error or company does not exist, etc.
        //maybe display a snackbar (from material UI) that this company is not available.
        return false;
    }

    return true;
};




/* Utility function that allows us to construct the companiesData state field more easily.
Accepts an object and fills in companiesData appropriately, substituting empty array where
needed.
 */
const createCompaniesData = (companiesData) => {
    return {
        momentumTraffic: companiesData.momentumTraffic || [],
        momentumInfluencerPosts: companiesData.momentumInfluencerPosts || [],
        momentumComposite: companiesData.momentumComposite || [],
        positionEmployees: companiesData.positionEmployees || [],
        positionValuation: companiesData.positionValuation || [],
        positionCumRaised: companiesData.positionCumRaised || [],
        momentumTable: companiesData.momentumTable || [],
        positionTable: companiesData.positionTable || [],
    };
};

/* Given a OBJ with a data attribute, pushes OBJ to ARR
if obj.data is non empty
 */
const pushIfObjDataNonEmpty = (obj, arr) => {
    if (obj.data.length !== 0) {
        arr.push(obj);
    }
};

/* Temporary utility function that, given a data array DATA and a site name
SITE, will create a valid json object (keys: id, data) that nivo can consume.
 */
const createTrafficObj = (site, data) => (
    {
        id: site,
        data: data,
    }
);

/* Temporary utility function that, given a site name SITE, will create
 a valid json object (keys: competitor, momentum, capitalFormation, YOYTraffic,
 QTRTraffic, industryInfluencer) for our momentum table.
 */
const createMomentumTableObj = (momentumTableData) => (
    {
        id: momentumTableData.site,
        momentum: momentumTableData.momentum || 0,
        capitalFormation: momentumTableData.capitalFormation || 0,
        YOYTraffic: momentumTableData.yoyTraffic || 0,
        QTRTraffic: momentumTableData.qtrTraffic || 0,
        industryInfluencer: momentumTableData.industryInfluencer || 0
    }
);

/* Temporary utility function that, given a site name SITE, will create
 a valid json object (keys: competitor, position, cumulativeCapital, valuation,
 globalWeb, webLink) for our position table.
 */
const createPositionTableObj = (positionTableData) => (
    {
        id: positionTableData.site,
        position: positionTableData.position || 0,
        cumulativeCapital: positionTableData.cumulativeCapital || 0,
        valuation: positionTableData.valuation || 0,
        globalWeb: positionTableData.globalWeb || 0,
        webLink: positionTableData.webLink || 0
    }
);






/* Async function that, given a company site, will go and fetch that
data from our backend server.
 */
const fetchCompanyData = async(site) => {
    let response = null;

    try {
        let currentSess = await Auth.currentSession();
        let accessToken = currentSess.getAccessToken();
        let formData = new FormData();
        formData.set("site", site);
        formData.set("tracAppKey", process.env.REACT_APP_TRAC_APP_KEY);

        try {
            response = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken.getJwtToken()}`
                },
                timeout: 10000,
                url: process.env.REACT_APP_COMPANY_INFO_ENDPOINT,
                data: formData});
        } catch (e) {
            return {fetchError: "Network"}
        }
    } catch (e) {
        return {fetchError: "Auth"}
    }

    return response;
};

/* Async function that, given a target company and a competitor, will go
and tell our server to create that competitor relationship.
 */
const establishCompetitorRelationship = async(target, competitorObj, action) => {
    let response = null;

    try {
        let currentSess = await Auth.currentSession();
        let accessToken = currentSess.getAccessToken();
        let formData = new FormData();
        formData.set("source", target);
        formData.set("tracAppKey", process.env.REACT_APP_TRAC_APP_KEY);
        formData.set("targets", JSON.stringify([competitorObj]));
        formData.set("action", action);

        try {
            response = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken.getJwtToken()}`
                },
                timeout: 10000,
                url: process.env.REACT_APP_SET_COMPETITORS_ENDPOINT,
                data: formData
            });
        } catch (e) {
            return {fetchError: "Network"}
        }
    } catch (e) {
        return {fetchError: "Auth"}
    }

    return response;
};



/* Update the companiesData and companies Redux State with the given data */
const updateReduxCompaniesData = (data, dispatch, company) => {
    /* Let's create the companiesData object */
    let momentumCompositeData = [];
    let momentumTrafficData = [];
    let momentumTableData = [];
    let positionTableData = [];
    let currCompaniesData = {
        momentumComposite: momentumCompositeData,
        momentumTraffic: momentumTrafficData,
        momentumTable: momentumTableData,
        positionTable: positionTableData
    };


    /* Add the company's composite momentum data */
    pushIfObjDataNonEmpty(data.momentum.graph.actual, momentumCompositeData);
    pushIfObjDataNonEmpty(data.momentum.graph.projected, momentumCompositeData);
    pushIfObjDataNonEmpty(createTrafficObj(data.site, data.monthlyTraffic), momentumTrafficData);
    momentumTableData.push(createMomentumTableObj(
        {
            site: data.site, momentum: data.rank, qtrTraffic: data.quarterOverQuarterGrowth,
            yoyTraffic: data.yearOverYearGrowth
        })
    );
    positionTableData.push(createPositionTableObj({site: data.site}));
    // momentumTrafficData.push(createTrafficObj(data.site, data.monthlyTraffic));


    let companiesData = createCompaniesData(currCompaniesData);

    //process the data coming out of the api
    dispatch(dataSlice.actions.updateCompaniesFromTable({companies: [company]}));
    dispatch(dataSlice.actions.updateTableCompaniesData({companyData: companiesData}));
};

/* Our async action creators that will fetch data over the network.
* Using redux-thunk under the hood to allow us to return a function.*/

export const addAutocompleteCompanies = () => {
    return async(dispatch, getState) => {
        let listOfCompanies = [{site: "Autocompletion Unavailable"}];
        let response = null;

        // call our API endpoint to get all companies

        let currentSess = null;
        let accessToken = null;

        try {
            currentSess = await Auth.currentSession();
            accessToken = currentSess.getAccessToken();
        } catch (error) {
            // handleErrors({fetchError: "Auth"}, dispatch);
            return;
        }

        try {
            let formData = new FormData();
            formData.set("tracAppKey", process.env.REACT_APP_TRAC_APP_KEY);
            response = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken.getJwtToken()}`
                },
                timeout: 10000,
                url: process.env.REACT_APP_AUTOCOMPLETE_ENDPOINT,
                data: formData
            });
            listOfCompanies = response.data.response.companies;
        } catch (error) {
            // handleErrors({fetchError: "Network"}, dispatch);
            return;
        } finally {
            if (listOfCompanies.length > 1) {
                let action = dataSlice.actions.updateAutocompleteCompanies(listOfCompanies);
                dispatch(action);
            }
        }
    };
};

/* Handles logic for updating dashboard for new overall company.*/
export const addOverallCompany = (company) => {
    return async(dispatch, getState) => {
        try {
            dispatch(toggleLoading({loading: true}));

            // fetch data from API for this company (will also receive competitor data)
            let fetchedCompanyData = await fetchCompanyData(company);

            if (!handleErrors(fetchedCompanyData, dispatch)) {
                dispatch(toggleLoading({loading: false}));
                return;
            }

            //process the data coming out of the api
            let data = fetchedCompanyData.data.response.company;
            // let competitorNames = data.competitors ? data.competitors.map(compObj => compObj.site) : [];
            let competitorNames = data.competitors.map(compObj => compObj.site);
            competitorNames.push(data.site);
            dispatch(dataSlice.actions.updateCompany({company: data.site, companyId: data.internalID}));
            dispatch(dataSlice.actions.updateCompanies({companies: competitorNames}));

            // update the dbCompetitors
            let dbCompetitors = data.competitors.map(compObj => (
                {
                    site: compObj.site,
                    internalID: compObj.internalID,
                    score: compObj.score || 100
                })
            );
            dispatch(dataSlice.actions.updateDBCompetitors({dbCompetitors: dbCompetitors}));


            /* Let's create the companiesData object */
            let momentumCompositeData = [];
            let momentumTrafficData = [];
            let momentumTableData = [];
            let positionTableData = [];
            let currCompaniesData = {
                momentumComposite: momentumCompositeData,
                momentumTraffic: momentumTrafficData,
                momentumTable: momentumTableData,
                positionTable: positionTableData
            };

            /* Add the company's composite momentum data */
            pushIfObjDataNonEmpty(data.momentum.graph.actual, momentumCompositeData);
            pushIfObjDataNonEmpty(data.momentum.graph.projected, momentumCompositeData);
            pushIfObjDataNonEmpty(createTrafficObj(data.site, data.monthlyTraffic), momentumTrafficData);
            momentumTableData.push(createMomentumTableObj(
                {
                    site: data.site, momentum: data.rank, qtrTraffic: data.quarterOverQuarterGrowth,
                    yoyTraffic: data.yearOverYearGrowth
                })
            );
            positionTableData.push(createPositionTableObj({site: data.site}));
            // momentumTrafficData.push(createTrafficObj(data.site, data.monthlyTraffic));

            /* Add composite momentum data for each competitor that has this data*/
            data.competitors.forEach(comp => {
                if (comp.momentum.actual.length !== 0) {
                    momentumCompositeData.push(comp.momentum.graph.actual);
                    momentumCompositeData.push(comp.momentum.graph.projected);
                }
                pushIfObjDataNonEmpty(createTrafficObj(comp.site, comp.monthlyTraffic), momentumTrafficData);
                momentumTableData.push(createMomentumTableObj(
                    {
                        site: comp.site, momentum: comp.rank, qtrTraffic: comp.quarterOverQuarterGrowth,
                        yoyTraffic: comp.yearOverYearGrowth
                    })
                );
                positionTableData.push(createPositionTableObj({site: comp.site}));
                // momentumTrafficData.push(createTrafficObj(comp.site, comp.monthlyTraffic));

            });
            let companiesData = createCompaniesData(currCompaniesData);

            dispatch(dataSlice.actions.updateAllCompaniesData({companiesData: companiesData}));
        } catch (error) {
            if (process.env.NODE_ENV === "development") {
                throw(error);
            }
            dispatch(dataSlice.actions.pushSnackbar({
                snackbar: {
                    message: "A network error was encountered. Please try again later",
                    options: {
                        variant: "error"
                    }
                }
            }));
        } finally {
            dispatch(toggleLoading({loading: false}));
        }
    };
};

/* Handles logic for adding a single company to our existing group of companies. */
export const addTableCompany = () => {
    return async(dispatch, getState) => {

        try {
            let company = getState().tableSearchTerm;
            dispatch(dataSlice.actions.updateTableSearchTerm({tableSearchTerm: ""}));
            if (getState().companies.includes(company)) {
                dispatch(dataSlice.actions.pushSnackbar({
                    snackbar: {
                        message: "You've already added that company",
                        options: {
                            variant: "error"
                        }
                    }
                }));
                return
            }

            // fetch data from API for this company (will also receive competitor data)
            let fetchedCompanyData = await fetchCompanyData(company);

            if (!handleErrors(fetchedCompanyData, dispatch)) {
                dispatch(toggleLoading({loading: false}));
                return;
            }


            let data = fetchedCompanyData.data.response.company;

            updateReduxCompaniesData(data, dispatch, company);
        } catch (error) {
            if (process.env.NODE_ENV === "development") {
                throw(error);
            }
            dispatch(dataSlice.actions.pushSnackbar({
                snackbar: {
                    message: "A network error was encountered. Please try again later",
                    options: {
                        variant: "error"
                    }
                }
            }));
        } finally {
            dispatch(toggleLoading({loading: false}));
        }
    };
};

/* Handles logic for adding a competitor to our database and our redux state */
export const addCompetitorRelation = () => {
    return async(dispatch, getState) => {

        try {
            let targetCompany = getState().company;
            let competitor = getState().tableSearchTerm;
            dispatch(dataSlice.actions.updateTableSearchTerm({tableSearchTerm: ""}));
            if (getState().dbCompetitors.find(compObj => compObj.site === competitor)) {
                dispatch(dataSlice.actions.pushSnackbar({
                    snackbar: {
                        message: "You've already added that company",
                        options: {
                            variant: "error"
                        }
                    }
                }));
                return
            }

            // tell the database that this company is a competitor
            let competitorObj = {
                site: competitor,
                score: 100,
            };
            // let competitorRelationResponse = await establishCompetitorRelationship(targetCompany, competitorObj, "create");
            // let fetchedCompanyData = await fetchCompanyData(competitor);

            let [competitorRelationResponse, fetchedCompanyData] =
                await Promise.all([establishCompetitorRelationship(targetCompany, competitorObj, "create"),
                    fetchCompanyData(competitor)]
                );

            if (!handleErrors(fetchedCompanyData, dispatch, true) || !handleErrors(competitorRelationResponse, dispatch)) {
                dispatch(toggleLoading({loading: false}));
                return;
            }

            let data = fetchedCompanyData.data.response.company;

            if (!getState().companies.includes(competitor)) {
                updateReduxCompaniesData(data, dispatch, competitor);
            }

            // update the dbCompetitors with the newly added competitor
            let dbCompetitor = [{
                site: data.site,
                internalID: data.internalID,
                score: data.score || 100
            }];

            dispatch(dataSlice.actions.updateSingleDBCompetitors({dbCompetitor: dbCompetitor}));

        } catch (error) {
            if (process.env.NODE_ENV === "development") {
                throw(error);
            }
            dispatch(dataSlice.actions.pushSnackbar({
                snackbar: {
                    message: "A network error was encountered. Please try again later",
                    options: {
                        variant: "error"
                    }
                }
            }));
        } finally {
            dispatch(toggleLoading({loading: false}));
        }
    };
};

/* Handles logic for deleting a competitor to our database and our redux state */
export const deleteCompetitorRelation = ({company}) => {
    return async(dispatch, getState) => {

        try {
            let targetCompany = getState().company;
            let competitor = company;
            // if (getState().companies.includes(company)) {
            //     dispatch(dataSlice.actions.pushSnackbar({
            //         snackbar: {
            //             message: "You've already added that company",
            //             options: {
            //                 variant: "error"
            //             }
            //         }
            //     }));
            //     return
            // }

            // tell the database that this company is a competitor
            let competitorObj = getState().dbCompetitors.find(elem => elem.site === competitor);
            let response = await establishCompetitorRelationship(targetCompany, competitorObj, "delete");

            if (!handleErrors(response, dispatch)) {
                dispatch(toggleLoading({loading: false}));
                return;
            }

            dispatch(dataSlice.actions.deleteDBCompetitors({competitor: competitor}));
            dispatch(dataSlice.actions.deleteCompany({company: competitor}));

        } catch (error) {
            if (process.env.NODE_ENV === "development") {
                throw(error);
            }
            dispatch(dataSlice.actions.pushSnackbar({
                snackbar: {
                    message: "A network error was encountered. Please try again later",
                    options: {
                        variant: "error"
                    }
                }
            }));
        } finally {
            dispatch(toggleLoading({loading: false}));
        }
    };
};



const {actions, reducer } = dataSlice;
export const rootReducer = reducer;
export const {updateOverallSearchTerm, updateTableSearchTerm, updateCompanies, deleteCompany,
    updateAutocompleteCompanies, updateAllCompaniesData, updateTableCompaniesData,
    updateWebTrafficTimeFrame, updateCompositeMomentumTimeFrame, updateInfluencerPostsTimeFrame,
    updateEmployeesTimeFrame, updateValuationTimeFrame, pushSnackbar, removeSnackbar, toggleLoading,
    updateDBCompetitors, toggleShouldLoadDefault } = actions;
